import React, { useState, useEffect } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { baseTheme } from './assets/global/Theme-variable';
import Themeroutes from "./routes/ThemeRoutes";
import Cookies from "js-cookie";
import Services from "./Services/Services";
import { jwtDecode } from "jwt-decode";
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate, Link } from "react-router-dom";
import { decrypt } from "../src/Utils/cryptoUtils";


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const routing = useRoutes(Themeroutes);
  const secureFlag = { secure: true }; 
  const theme = baseTheme;
  const navigate = useNavigate();

  useEffect(() => {
    const validateAccessToken = async () => {
      const cookiesTokenValue = Cookies.get("access_token");
  
      if (cookiesTokenValue) {
        const decodeToken = jwtDecode(cookiesTokenValue);
        const token_expireTime = decodeToken.exp;
        const current_time = Math.floor(Date.now() / 1000);
  
        if (decodeToken) {
          if (current_time >= token_expireTime) {
  
            const refreshToken = Cookies.get("refresh_token");  
            const bodyjson = {
              refresh_token: refreshToken,
            };
            try {
              const response = await Services.IVPValidateAccessTokengenByRefreshToken(bodyjson);
              if (response.status === 200) {
                const newAccessToken = response.data.data.access_token;
                Cookies.set("access_token", newAccessToken, {
                  expires: 1,
                  ...secureFlag,
                });
  
                const newRefreshToken = response.data.data.refresh_token;
                Cookies.set("refresh_token", newRefreshToken, {
                  expires: 1,
                  ...secureFlag,
                });
  
              }
            } catch (error) {
              }
            }
        }
      }
    };
    validateAccessToken();
  }, []);

  const onIdle = () => {
    handleLogout();
  };


  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 30 * 60 * 1000, 
  });

  const handleLogout = async () => {
    try {
      const session_key = Cookies.get("session_key");
      const encryptedUserId = Cookies.get("abc");
      const user_id = encryptedUserId ? decrypt(encryptedUserId) : null;
      const access_token = Cookies.get("access_token");
      const LoggedIn = Cookies.get("LoggedIn");
      const bearer_token = Cookies.get("bearer_token");
      if (LoggedIn === "true") {
        await Services.IvpLogout(user_id); 
      } else {
        await Services.logout(session_key);
      }
      Cookies.remove("session_key");
      Cookies.remove("access_token");
      Cookies.remove("abc");
      Cookies.remove("bearer_token");
      Cookies.remove("LoggedIn");
      Cookies.remove("ivpflag");
      localStorage.clear();
      navigate("/"); 
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {  routing}
    </ThemeProvider>
  );

};

export default App;


