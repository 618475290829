import axios from "axios";
import {
  API_BASE_URL,
  IVP_BASE_URL,
  FAAS_BASE_URL,
  LMS_BASE_URL,
  ASSESSMENT_BASE_URL,
} from "../config/config";
import Cookies from "js-cookie";

const token = Cookies.get("bearer_token");
const authAxios = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

class Services {
  Ivpauthorize(body) {
    return axios.post(IVP_BASE_URL + "tokengen", body);
  }

  Userinfo(access_token, refresh_token) {
    return axios.post(IVP_BASE_URL + "userinfo", refresh_token, {
      headers: { Authorization: `Bearer ${access_token}` },
    });
  }

  // IVPRewardpoints(data) {
  //   return axios.post(IVP_BASE_URL + "/totalRewardPoint", data);
  // }

  // IVPRewardpointsDetails(data) {
  //   return axios.post(IVP_BASE_URL + "/lastActivityByApp", data);
  // }

  IVPRewardpoints(data) {
    return authAxios.post(IVP_BASE_URL + "totalRewardPoint", data);
  }

  IVPRewardpointsDetails(data) {
    return authAxios.post(IVP_BASE_URL + "lastActivityByApp", data);
  }

  // IVPLogout(body) {
  //   return axios.post(IVP_BASE_URL + "logout?client_id=ciso", body);
  // }

  IvpLogout(user_id) {
    return axios.post(IVP_BASE_URL + "ivplogout", { user_id });
  }

  IVPValidateAccessTokengenByRefreshToken(body) {
    return axios.post(IVP_BASE_URL + "accessTokengen?client_id=ciso", body);
  }

  // IvpLogin(data) {
  //   return axios.post(API_BASE_URL + "/ivp_login/", data);
  // }

  IvpLogin(data) {
    return axios.post(API_BASE_URL + "/login_ivp/", data);
  }

  IvpProfileLogin(bodyjson) {
    return axios.post(API_BASE_URL + "/ivp_login/", bodyjson);
  }

  IvpProfile(uid) {
    return authAxios.post(IVP_BASE_URL + "ivp/profile/", { uid });
  }

  updateProfile = (data) => {
    return axios.post(`${IVP_BASE_URL}/updateuserbyid`, data);
  };

  createEvent(eventData) {
    return axios.post(IVP_BASE_URL + "ivp/createEvent/", eventData);
  }

  updateEvent(eventData) {
    return axios.post(IVP_BASE_URL + "ivp/updateEvent/", eventData);
  }

  getfaasdetails(event_unicode) {
    return axios.post(FAAS_BASE_URL + "/geteventurl/", event_unicode);
  }

  geteventdetails(bodyjson) {
    return axios.post(`${IVP_BASE_URL}ivp/`, bodyjson);
  }

  IvpTrainingComponents() {
    return axios.get(IVP_BASE_URL + "getcomponent");
  }

  IvpTrainingClusters() {
    return axios.get(IVP_BASE_URL + "thematicarea");
  }

  getAllStates() {
    return axios.get(IVP_BASE_URL + "getallstate");
  }

  getDistrictsByState(stateId) {
    return axios.get(`${IVP_BASE_URL}getdistrictbystate?state=${stateId}`);
  }

  getintendedusers() {
    return axios.get(IVP_BASE_URL + "user_category");
  }

  getAllTrainings() {
    return axios.get(IVP_BASE_URL + "ivp/eventList/");
  }

  getEventUsers(eventUnicode) {
    return axios.get(`${IVP_BASE_URL}ivp/eventUserList/${eventUnicode}/`);
  }

  getServices(eventUnicode) {
    return axios.get(`${IVP_BASE_URL}ivp/eventServiceList/${eventUnicode}/`);
  }

  fetchServices(eventUnicode) {
    return axios.get(`${IVP_BASE_URL}ivp/eventServiceList/${eventUnicode}/`);
  }

  GetEventCount() {
    return axios.get(`${IVP_BASE_URL}ivp/eventCount/`);
  }

  fetchCoursesFromLMS() {
    const apiUrl = `${LMS_BASE_URL}/webservice/rest/server.php`;
    const params = {
      wsfunction: "core_course_get_courses",
      moodlewsrestformat: "json",
      // wstoken: 'a3bea617416c5cabd4dd5f641bd7bedf'
      // wstoken: "07ca0988aaecf1d0e262c69a7b47d2dc",
      wstoken: "43ec19ed80019040b607c7750e3c7452",
    };
    const urlWithParams = `${apiUrl}?wsfunction=${params.wsfunction}&moodlewsrestformat=${params.moodlewsrestformat}&wstoken=${params.wstoken}`;
    return axios.get(urlWithParams);
  }

  updateCoursesInLMS(courseId, eventId) {
    const apiUrl = `${LMS_BASE_URL}/webservice/rest/server.php`;
    const params = {
      wsfunction: "core_course_update_courses",
      moodlewsrestformat: "json",
      wstoken: "c475313cc6c1891b5f33d6c831b730a2",
      "courses[0][id]": courseId,
      "courses[0][customfields][0][shortname]": "event",
      "courses[0][customfields][0][value]": eventId,
    };
    const urlWithParams = `${apiUrl}?wsfunction=${params.wsfunction}&moodlewsrestformat=${params.moodlewsrestformat}&wstoken=${params.wstoken}&courses[0][id]=${params["courses[0][id]"]}&courses[0][customfields][0][shortname]=${params["courses[0][customfields][0][shortname]"]}&courses[0][customfields][0][value]=${params["courses[0][customfields][0][value]"]}`;
    return axios.post(urlWithParams);
  }

  mapCourseToService(selectedCourse, getTrainingEventUnicode) {
    const service_uri = `${LMS_BASE_URL}/course/view.php?id=${selectedCourse.id}`;
    const data = {
      event_unicode: getTrainingEventUnicode,
      service_uri: service_uri,
      client_id: "elearning",
      service_name: selectedCourse.fullname,
      logo: "",
    };
    return axios.post(IVP_BASE_URL + "ivp/addEventSevice/", data);
  }

  deleteService(selectedCourse, eventUnicode) {
    const service_uri = `${LMS_BASE_URL}/course/view.php?id=${selectedCourse.id}`;
    const data = {
      event_unicode: eventUnicode,
      service_uri: selectedCourse.service_uri,
      client_id: "elearning",
      service_name: selectedCourse.service_name,
      logo: "",
    };
    return axios.post(IVP_BASE_URL + "ivp/deleteService/", data);
  }

  getAssessmentServices(eventUnicode) {
    return axios.post(
      ASSESSMENT_BASE_URL + "/quiz-ctf/quiz/get-assessment-list",
      { event_unicode: [eventUnicode] }
    );
  }

  getFormsServices(eventUnicode) {
    return axios.post(FAAS_BASE_URL + "/event_form_details/", {
      event_unicode: eventUnicode,
    });
  }

  getAssessmentServices(eventUnicode) {
    return axios.post(
      ASSESSMENT_BASE_URL + "/quiz-ctf/quiz/get-assessment-list",
      { event_unicode: [eventUnicode] }
    );
  }

  UploadCertificate(formData) {
    return axios.post(
      ASSESSMENT_BASE_URL +
        "/quiz-ctf/awareness/upload/certificate-for-awareness",
      formData
    );
  }

  getCertificate() {
    return axios.get(ASSESSMENT_BASE_URL + "/quiz-ctf/awareness/getall");
  }
}
export default new Services();
