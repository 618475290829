//================================ Local URLs ==========================================//

// export const API_BASE_URL = "http://10.244.1.26:8002";
// export const API_BASE_URL_1 = "http://10.244.1.26:8001";
// export const CISO_BASE_URL = "http://sripradha.hyderabad.cdac.in:3000";
// export const IVP_BASE_URL = "http://dhananjayk.hyderabad.cdac.in/backend/";
// export const FAAS_BASE_URL = "http://10.244.3.107:8001";
// export const LMS_BASE_URL = "http://courses.isea.in";
// export const ASSESSMENT_BASE_URL = "http://10.244.3.75:9090";
// export const CTF_BASE_URL = "http://10.244.2.255:3002/proceed-to-ctf";
// export const VLABS_BASE_URL = "http://10.244.3.15:8080/Social/";

//================================ Staging URLs ==========================================//

// export const API_BASE_URL = "https://ciso.isea.in/backend";
// export const API_BASE_URL_1 = "https://ciso.isea.in/backend";
// export const CISO_BASE_URL = "https://ciso.isea.in";
// export const IVP_BASE_URL = "https://ivp.isea.in/backend/";
// export const FAAS_BASE_URL = "https://faas.isea.in/backend";
// export const LMS_BASE_URL = "http://courses.isea.in";
// export const ASSESSMENT_BASE_URL = "https://assessment.isea.in/procced-to-assessment";
// export const CTF_BASE_URL = "https://ctf.isea.in/procced-to-ctf";
// export const VLABS_BASE_URL = "https://vlab.isea.in/Social/";

//================================ Production URLs ==========================================//

export const API_BASE_URL = "https://ciso.isea.app/backend";
export const API_BASE_URL_1 = "https://ciso.isea.app/backend";
export const CISO_BASE_URL = "https://ciso.isea.app";
export const IVP_BASE_URL = "https://ivp.isea.app/backend/";
export const FAAS_BASE_URL = "https://faas.isea.app/backend";
export const LMS_BASE_URL = " https://courses.isea.app";
export const ASSESSMENT_BASE_URL = "https://assessment.isea.app";
export const CTF_BASE_URL = "https://ctf.isea.app/procced-to-ctf";
export const VLABS_BASE_URL = "https://vlab.isea.app";
